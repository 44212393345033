@import "../../Styles/theme.scss";

.splash_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  padding: 24px;
  box-sizing: border-box;
}

.do_not_flex_container {
  @extend .splash_container;
  flex: 0;
}

.splash_top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}  
.half_vh_splash_top {
  @extend .splash_top;
  width: 50vh;
}

.icon {
  align-self: flex-start;
  margin-bottom: 20px;
  margin-top: 40px;
}

.installation_header {
  color: $neutral7;
  font-weight: $mediumWeight;
  font-size: $heading2;
  line-height: normal;
}

.installation_message {
  margin-top: 16px;
  color: $neutral6;
  font-weight: $regular;
  font-size: $bodyMedium;
}

.message_item {
  padding-bottom: 12px;
}

.splash_bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  margin-top: 24px;
}

@media (max-width: $mobile-break-point) {
  .half_vh_splash_top {
    width: unset;
  }
  .splash_bottom {
    flex-direction: column-reverse;
    gap: 16px;
  }
}

.email {
  color: $secondary3;
  text-decoration: underline;
  text-underline-offset: 2px;
}
