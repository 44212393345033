@import "../../../Styles/theme.scss";

.page {
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $neutral1;
  font-family: Inter;
  align-items: flex-start !important;
}

.flex_direction {
  flex-direction: row;
}

.start {
  display: flex;
  gap: 32px;
}

.link {
  display: flex;
  align-self: center;
  margin-top: 16px;
  color: $primary4;
  text-decoration: underline;
  line-height: 26px;
}

.card_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card_right {
  display: flex;
  flex-direction: row;
}

.qr_code_right_wrapper {
  margin-right: 28px;
}

.instructions {
  margin-top: 12px;
  text-align: left;
  font-weight: $regular;
  font-size: $bodySmall;
  color: $neutral7;
  p {
    font-size: $bodyMedium;
  }
  h2 {
    font-size: $heading2;
  }
}

.buttons {
  display: flex !important;
  width: 100%;
  flex-direction: row !important;
  justify-content: flex-end !important;
  margin-top: 36px;
}

.qr_loading_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: start;
  padding: 24px;
  box-sizing: border-box;
}

.connecting_user_to_hub {
  font-weight: $mediumWeight;
  font-size: $heading2;
  line-height: normal;
  color: $neutral7;
}

.install_flow_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 10px;
}

@media (max-width: $tablet-break-point) { // 900px
  .page {
    background-color: $white;
    padding: 0;
  }
  .start {
    padding: 24px;
    flex-direction: column;
  }
  .content {
    padding: $mobile-padding;
  }
  .card_left {
    flex-direction: row;
  }
  .card_right {
    justify-content: space-between;
  }
  .qr_code_right_wrapper {
    margin-right: 0px;
  }
  .qr_code_left_wrapper {
    margin-left: 28px;
  }
  .install_flow_buttons {
    flex-direction: column-reverse;
    padding: 24px;
  }

  .header {
    padding: 0px 24px;
  }
}

@media (max-width: 670px) {
  .card_left {
    flex-direction: row;
    flex: 1;
  }
  .qr_code_left_wrapper {
    width: 300px;
    margin-left: 20px;
  }
}

@media (max-width: 663px) {
  .qr_code_left_wrapper {
    width: 270px;
  }
}

@media (max-width: 480px) {
  .qr_code_left_wrapper {
    width: 250px;
  }
}

@media (max-width: 460px) {
  .qr_code_left_wrapper {
    width: 240px;
  }
}

@media (max-width: 440px) {
  .qr_code_left_wrapper {
    width: 220px;
  }
}

@media (max-width: 432px) {
  .qr_code_left_wrapper {
    width: 198px;
  }
}

@media (max-width: 400px) {
  .card_left {
    flex-direction: column-reverse;
  }
  .qr_code_left_wrapper {
    width: 200px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .buttons {
    width: 100%;
    flex-direction: column-reverse !important;
    justify-content: space-between;
  }
}
