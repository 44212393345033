@import "../../Styles/theme.scss";

.task_list_container {
  position: absolute;
  top: 10px;
  right: 140px;
  width: 300px;
  background-color: $white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 1000;

  @media (max-width: 768px) {
    right: 20px;
    width: 260px;
  }

  @media (max-width: 480px) {
    right: 10px;
    width: 90%;
    padding: 12px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.heading {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  flex: 1;
}

.close_button {
  font-size: 24px;   
  font-weight: $mediumWeight;
  color: $neutral6;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  &:hover {
    color: $risk6; /* Darker red on hover for better feedback */
  }
}

.subheading {
  font-size: $bodyMedium;
  color: $neutral7;
  margin-bottom: 16px;
}

.progress_bar {
  display: flex;
  margin-bottom: 16px;
}

.progress_segment {
  flex: 1;
  height: 4px;
  background-color: $neutral3;
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }
}

.completed {
  background-color: $primary3; 
}

.task_list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.task_item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}

.completed_task {
  cursor: default;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: $neutral3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $bodySmall;
  font-weight: bold;

  &.green_check {
    background-color: $primary3;
    color: $white;
  }
}

.text {
  flex: 1;
  font-size: 14px;
  color: $neutral6;
}

.completed_text {
  text-decoration: line-through;
  color: $neutral5;
}

.widget_is_visible {
  font-size: $bodySmall;
  color: $secondary3;
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  margin-top: 16px;
}

.widget_is_hidden {
  @extend .widget_is_visible;
  margin-top: 0px;
}
